import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"assessment-banner--certification {{if @shouldBlurBanner \"blur-banner\"}}\">\n  <div class=\"assessment-banner-container\">\n    <div class=\"assessment-banner__pix-logo\">\n      <img src=\"/images/pix-logo-blanc.svg\" role=\"none\" alt=\"\" />\n    </div>\n    <div class=\"assessment-banner__splitter\"></div>\n    <h1 class=\"assessment-banner__title\">{{this.candidateFullName}}</h1>\n\n    <div class=\"certification-number\">\n      <div class=\"certification-number__label\">{{t \"pages.challenge.certification.banner.certification-number\"}}</div>\n      <div class=\"certification-number__value\">{{@certificationNumber}}</div>\n    </div>\n  </div>\n</div>", {"contents":"<div class=\"assessment-banner--certification {{if @shouldBlurBanner \"blur-banner\"}}\">\n  <div class=\"assessment-banner-container\">\n    <div class=\"assessment-banner__pix-logo\">\n      <img src=\"/images/pix-logo-blanc.svg\" role=\"none\" alt=\"\" />\n    </div>\n    <div class=\"assessment-banner__splitter\"></div>\n    <h1 class=\"assessment-banner__title\">{{this.candidateFullName}}</h1>\n\n    <div class=\"certification-number\">\n      <div class=\"certification-number__label\">{{t \"pages.challenge.certification.banner.certification-number\"}}</div>\n      <div class=\"certification-number__value\">{{@certificationNumber}}</div>\n    </div>\n  </div>\n</div>","moduleName":"mon-pix/components/certification-banner.hbs","parseOptions":{"srcName":"mon-pix/components/certification-banner.hbs"}});
import Component from '@glimmer/component';
import startCase from 'lodash/startCase';

export default class CertificationBanner extends Component {
  get candidateFullName() {
    let fullName = '';
    if (this.args && this.args.certification) {
      const firstName = this.args.certification.get('firstName');
      const lastName = this.args.certification.get('lastName');
      fullName = `${startCase(firstName)} ${lastName.toUpperCase()}`;
    }
    return fullName;
  }
}
